/*!
* QWeather Icons (https://icons.qweather.com)
* Copyright QWeather 和风天气 (https://www.qweather.com)
* License:  Code for MIT, Icons for CC BY 4.0
*/

@font-face {
  font-family: "qweather-icons";
  src: url("./fonts/qweather-icons.woff2?6f3002707c50c5ebabadf80f467656a8") format("woff2"),
url("./fonts/qweather-icons.woff?6f3002707c50c5ebabadf80f467656a8") format("woff"),
url("./fonts/qweather-icons.ttf?6f3002707c50c5ebabadf80f467656a8") format("truetype");
}

[class^="qi-"]::before,
[class*=" qi-"]::before {
  display: inline-block;
  font-family: "qweather-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.qi-100::before { content: "\f101"; }
.qi-101::before { content: "\f102"; }
.qi-102::before { content: "\f103"; }
.qi-103::before { content: "\f104"; }
.qi-104::before { content: "\f105"; }
.qi-150::before { content: "\f106"; }
.qi-151::before { content: "\f107"; }
.qi-152::before { content: "\f108"; }
.qi-153::before { content: "\f109"; }
.qi-300::before { content: "\f10a"; }
.qi-301::before { content: "\f10b"; }
.qi-302::before { content: "\f10c"; }
.qi-303::before { content: "\f10d"; }
.qi-304::before { content: "\f10e"; }
.qi-305::before { content: "\f10f"; }
.qi-306::before { content: "\f110"; }
.qi-307::before { content: "\f111"; }
.qi-308::before { content: "\f112"; }
.qi-309::before { content: "\f113"; }
.qi-310::before { content: "\f114"; }
.qi-311::before { content: "\f115"; }
.qi-312::before { content: "\f116"; }
.qi-313::before { content: "\f117"; }
.qi-314::before { content: "\f118"; }
.qi-315::before { content: "\f119"; }
.qi-316::before { content: "\f11a"; }
.qi-317::before { content: "\f11b"; }
.qi-318::before { content: "\f11c"; }
.qi-350::before { content: "\f11d"; }
.qi-351::before { content: "\f11e"; }
.qi-399::before { content: "\f11f"; }
.qi-400::before { content: "\f120"; }
.qi-401::before { content: "\f121"; }
.qi-402::before { content: "\f122"; }
.qi-403::before { content: "\f123"; }
.qi-404::before { content: "\f124"; }
.qi-405::before { content: "\f125"; }
.qi-406::before { content: "\f126"; }
.qi-407::before { content: "\f127"; }
.qi-408::before { content: "\f128"; }
.qi-409::before { content: "\f129"; }
.qi-410::before { content: "\f12a"; }
.qi-456::before { content: "\f12b"; }
.qi-457::before { content: "\f12c"; }
.qi-499::before { content: "\f12d"; }
.qi-500::before { content: "\f12e"; }
.qi-501::before { content: "\f12f"; }
.qi-502::before { content: "\f130"; }
.qi-503::before { content: "\f131"; }
.qi-504::before { content: "\f132"; }
.qi-507::before { content: "\f133"; }
.qi-508::before { content: "\f134"; }
.qi-509::before { content: "\f135"; }
.qi-510::before { content: "\f136"; }
.qi-511::before { content: "\f137"; }
.qi-512::before { content: "\f138"; }
.qi-513::before { content: "\f139"; }
.qi-514::before { content: "\f13a"; }
.qi-515::before { content: "\f13b"; }
.qi-800::before { content: "\f13c"; }
.qi-801::before { content: "\f13d"; }
.qi-802::before { content: "\f13e"; }
.qi-803::before { content: "\f13f"; }
.qi-804::before { content: "\f140"; }
.qi-805::before { content: "\f141"; }
.qi-806::before { content: "\f142"; }
.qi-807::before { content: "\f143"; }
.qi-900::before { content: "\f144"; }
.qi-901::before { content: "\f145"; }
.qi-999::before { content: "\f146"; }
.qi-1001::before { content: "\f147"; }
.qi-1002::before { content: "\f148"; }
.qi-1003::before { content: "\f149"; }
.qi-1004::before { content: "\f14a"; }
.qi-1005::before { content: "\f14b"; }
.qi-1006::before { content: "\f14c"; }
.qi-1007::before { content: "\f14d"; }
.qi-1008::before { content: "\f14e"; }
.qi-1009::before { content: "\f14f"; }
.qi-1010::before { content: "\f150"; }
.qi-1011::before { content: "\f151"; }
.qi-1012::before { content: "\f152"; }
.qi-1013::before { content: "\f153"; }
.qi-1014::before { content: "\f154"; }
.qi-1015::before { content: "\f155"; }
.qi-1016::before { content: "\f156"; }
.qi-1017::before { content: "\f157"; }
.qi-1018::before { content: "\f158"; }
.qi-1019::before { content: "\f159"; }
.qi-1020::before { content: "\f15a"; }
.qi-1021::before { content: "\f15b"; }
.qi-1022::before { content: "\f15c"; }
.qi-1023::before { content: "\f15d"; }
.qi-1024::before { content: "\f15e"; }
.qi-1025::before { content: "\f15f"; }
.qi-1026::before { content: "\f160"; }
.qi-1027::before { content: "\f161"; }
.qi-1028::before { content: "\f162"; }
.qi-1029::before { content: "\f163"; }
.qi-1030::before { content: "\f164"; }
.qi-1031::before { content: "\f165"; }
.qi-1032::before { content: "\f166"; }
.qi-1033::before { content: "\f167"; }
.qi-1034::before { content: "\f168"; }
.qi-1035::before { content: "\f169"; }
.qi-1036::before { content: "\f16a"; }
.qi-1037::before { content: "\f16b"; }
.qi-1038::before { content: "\f16c"; }
.qi-1039::before { content: "\f16d"; }
.qi-1040::before { content: "\f16e"; }
.qi-1041::before { content: "\f16f"; }
.qi-1042::before { content: "\f170"; }
.qi-1043::before { content: "\f171"; }
.qi-1044::before { content: "\f172"; }
.qi-1045::before { content: "\f173"; }
.qi-1046::before { content: "\f174"; }
.qi-1047::before { content: "\f175"; }
.qi-1048::before { content: "\f176"; }
.qi-1049::before { content: "\f177"; }
.qi-1050::before { content: "\f178"; }
.qi-1051::before { content: "\f179"; }
.qi-1052::before { content: "\f17a"; }
.qi-1053::before { content: "\f17b"; }
.qi-1054::before { content: "\f17c"; }
.qi-1055::before { content: "\f17d"; }
.qi-1056::before { content: "\f17e"; }
.qi-1057::before { content: "\f17f"; }
.qi-1058::before { content: "\f180"; }
.qi-1059::before { content: "\f181"; }
.qi-1061::before { content: "\f182"; }
.qi-1064::before { content: "\f183"; }
.qi-1101::before { content: "\f184"; }
.qi-1302::before { content: "\f185"; }
.qi-1402::before { content: "\f186"; }
.qi-1601::before { content: "\f187"; }
.qi-1602::before { content: "\f188"; }
.qi-1603::before { content: "\f189"; }
.qi-1604::before { content: "\f18a"; }
.qi-1605::before { content: "\f18b"; }
.qi-1606::before { content: "\f18c"; }
.qi-1607::before { content: "\f18d"; }
.qi-2001::before { content: "\f18e"; }
.qi-2002::before { content: "\f18f"; }
.qi-2003::before { content: "\f190"; }
.qi-2004::before { content: "\f191"; }
.qi-2005::before { content: "\f192"; }
.qi-2006::before { content: "\f193"; }
.qi-2007::before { content: "\f194"; }
.qi-2008::before { content: "\f195"; }
.qi-2009::before { content: "\f196"; }
.qi-2010::before { content: "\f197"; }
.qi-2011::before { content: "\f198"; }
.qi-2012::before { content: "\f199"; }
.qi-2013::before { content: "\f19a"; }
.qi-2014::before { content: "\f19b"; }
.qi-2015::before { content: "\f19c"; }
.qi-2016::before { content: "\f19d"; }
.qi-2017::before { content: "\f19e"; }
.qi-2018::before { content: "\f19f"; }
.qi-9999::before { content: "\f1a0"; }
.qi-100-fill::before { content: "\f1a1"; }
.qi-101-fill::before { content: "\f1a2"; }
.qi-102-fill::before { content: "\f1a3"; }
.qi-103-fill::before { content: "\f1a4"; }
.qi-104-fill::before { content: "\f1a5"; }
.qi-150-fill::before { content: "\f1a6"; }
.qi-151-fill::before { content: "\f1a7"; }
.qi-152-fill::before { content: "\f1a8"; }
.qi-153-fill::before { content: "\f1a9"; }
.qi-300-fill::before { content: "\f1aa"; }
.qi-301-fill::before { content: "\f1ab"; }
.qi-302-fill::before { content: "\f1ac"; }
.qi-303-fill::before { content: "\f1ad"; }
.qi-304-fill::before { content: "\f1ae"; }
.qi-305-fill::before { content: "\f1af"; }
.qi-306-fill::before { content: "\f1b0"; }
.qi-307-fill::before { content: "\f1b1"; }
.qi-308-fill::before { content: "\f1b2"; }
.qi-309-fill::before { content: "\f1b3"; }
.qi-310-fill::before { content: "\f1b4"; }
.qi-311-fill::before { content: "\f1b5"; }
.qi-312-fill::before { content: "\f1b6"; }
.qi-313-fill::before { content: "\f1b7"; }
.qi-314-fill::before { content: "\f1b8"; }
.qi-315-fill::before { content: "\f1b9"; }
.qi-316-fill::before { content: "\f1ba"; }
.qi-317-fill::before { content: "\f1bb"; }
.qi-318-fill::before { content: "\f1bc"; }
.qi-350-fill::before { content: "\f1bd"; }
.qi-351-fill::before { content: "\f1be"; }
.qi-399-fill::before { content: "\f1bf"; }
.qi-400-fill::before { content: "\f1c0"; }
.qi-401-fill::before { content: "\f1c1"; }
.qi-402-fill::before { content: "\f1c2"; }
.qi-403-fill::before { content: "\f1c3"; }
.qi-404-fill::before { content: "\f1c4"; }
.qi-405-fill::before { content: "\f1c5"; }
.qi-406-fill::before { content: "\f1c6"; }
.qi-407-fill::before { content: "\f1c7"; }
.qi-408-fill::before { content: "\f1c8"; }
.qi-409-fill::before { content: "\f1c9"; }
.qi-410-fill::before { content: "\f1ca"; }
.qi-456-fill::before { content: "\f1cb"; }
.qi-457-fill::before { content: "\f1cc"; }
.qi-499-fill::before { content: "\f1cd"; }
.qi-500-fill::before { content: "\f1ce"; }
.qi-501-fill::before { content: "\f1cf"; }
.qi-502-fill::before { content: "\f1d0"; }
.qi-503-fill::before { content: "\f1d1"; }
.qi-504-fill::before { content: "\f1d2"; }
.qi-507-fill::before { content: "\f1d3"; }
.qi-508-fill::before { content: "\f1d4"; }
.qi-509-fill::before { content: "\f1d5"; }
.qi-510-fill::before { content: "\f1d6"; }
.qi-511-fill::before { content: "\f1d7"; }
.qi-512-fill::before { content: "\f1d8"; }
.qi-513-fill::before { content: "\f1d9"; }
.qi-514-fill::before { content: "\f1da"; }
.qi-515-fill::before { content: "\f1db"; }
.qi-900-fill::before { content: "\f1dc"; }
.qi-901-fill::before { content: "\f1dd"; }
.qi-999-fill::before { content: "\f1de"; }
.qi-sunny::before { content: "\f101"; }
.qi-cloudy::before { content: "\f102"; }
.qi-few-clouds::before { content: "\f103"; }
.qi-partly-cloudy::before { content: "\f104"; }
.qi-overcast::before { content: "\f105"; }
.qi-clear-night::before { content: "\f106"; }
.qi-cloudy-night::before { content: "\f107"; }
.qi-few-clouds-night::before { content: "\f108"; }
.qi-partly-cloudy-night::before { content: "\f109"; }
.qi-shower-rain::before { content: "\f10a"; }
.qi-heavy-shower-rain::before { content: "\f10b"; }
.qi-thundershower::before { content: "\f10c"; }
.qi-heavy-thunderstorm::before { content: "\f10d"; }
.qi-thundershower-with-hail::before { content: "\f10e"; }
.qi-light-rain::before { content: "\f10f"; }
.qi-moderate-rain::before { content: "\f110"; }
.qi-heavy-rain::before { content: "\f111"; }
.qi-extreme-rain::before { content: "\f112"; }
.qi-drizzle-rain::before { content: "\f113"; }
.qi-storm::before { content: "\f114"; }
.qi-heavy-storm::before { content: "\f115"; }
.qi-severe-storm::before { content: "\f116"; }
.qi-freezing-rain::before { content: "\f117"; }
.qi-light-to-moderate-rain::before { content: "\f118"; }
.qi-moderate-to-heavy-rain::before { content: "\f119"; }
.qi-heavy-rain-to-storm::before { content: "\f11a"; }
.qi-storm-to-heavy-storm::before { content: "\f11b"; }
.qi-heavy-to-severe-storm::before { content: "\f11c"; }
.qi-shower-rain-night::before { content: "\f11d"; }
.qi-heavy-shower-rain-night::before { content: "\f11e"; }
.qi-rain::before { content: "\f193"; }
.qi-light-snow::before { content: "\f120"; }
.qi-moderate-snow::before { content: "\f121"; }
.qi-heavy-snow::before { content: "\f167"; }
.qi-snowstorm::before { content: "\f123"; }
.qi-sleet::before { content: "\f124"; }
.qi-rain-and-snow::before { content: "\f125"; }
.qi-shower-snow::before { content: "\f126"; }
.qi-snow-flurry::before { content: "\f127"; }
.qi-light-to-moderate-snow::before { content: "\f128"; }
.qi-moderate-to-heavy-snow::before { content: "\f129"; }
.qi-heavy-snow-to-snowstorm::before { content: "\f12a"; }
.qi-shower-snow-night::before { content: "\f12b"; }
.qi-snow-flurry-night::before { content: "\f12c"; }
.qi-snow::before { content: "\f12d"; }
.qi-mist::before { content: "\f12e"; }
.qi-foggy::before { content: "\f12f"; }
.qi-haze::before { content: "\f159"; }
.qi-sand::before { content: "\f131"; }
.qi-dust::before { content: "\f132"; }
.qi-duststorm::before { content: "\f133"; }
.qi-sandstorm::before { content: "\f14d"; }
.qi-dense-fog::before { content: "\f135"; }
.qi-strong-fog::before { content: "\f136"; }
.qi-moderate-haze::before { content: "\f137"; }
.qi-heavy-haze::before { content: "\f138"; }
.qi-severe-haze::before { content: "\f139"; }
.qi-heavy-fog::before { content: "\f157"; }
.qi-extra-heavy-fog::before { content: "\f13b"; }
.qi-new-moon::before { content: "\f13c"; }
.qi-waxing-crescent::before { content: "\f13d"; }
.qi-first-quarter::before { content: "\f13e"; }
.qi-waxing-gibbous::before { content: "\f13f"; }
.qi-full-moon::before { content: "\f140"; }
.qi-waning-gibbous::before { content: "\f141"; }
.qi-last-quarter::before { content: "\f142"; }
.qi-waning-crescent::before { content: "\f143"; }
.qi-hot::before { content: "\f144"; }
.qi-cold::before { content: "\f168"; }
.qi-unknown::before { content: "\f146"; }
.qi-typhoon::before { content: "\f147"; }
.qi-tornado::before { content: "\f148"; }
.qi-rainstorm::before { content: "\f149"; }
.qi-snow-storm::before { content: "\f14a"; }
.qi-cold-wave::before { content: "\f14b"; }
.qi-gale::before { content: "\f14c"; }
.qi-low-temperature-freeze::before { content: "\f14e"; }
.qi-high-temperature::before { content: "\f14f"; }
.qi-heat-wave::before { content: "\f150"; }
.qi-dry-hot-air::before { content: "\f151"; }
.qi-downburst::before { content: "\f152"; }
.qi-avalanche::before { content: "\f153"; }
.qi-lightning::before { content: "\f154"; }
.qi-hail::before { content: "\f155"; }
.qi-frost::before { content: "\f156"; }
.qi-low-level-wind-shearl::before { content: "\f158"; }
.qi-thunder-gust::before { content: "\f15a"; }
.qi-road-icing::before { content: "\f15b"; }
.qi-drought::before { content: "\f15c"; }
.qi-gale-at-sea::before { content: "\f15d"; }
.qi-heat-stroke::before { content: "\f15e"; }
.qi-wildfire::before { content: "\f15f"; }
.qi-grassland-fire::before { content: "\f160"; }
.qi-freeze::before { content: "\f161"; }
.qi-space-weather::before { content: "\f162"; }
.qi-heavy-air-pollution::before { content: "\f163"; }
.qi-low-temperature-rain-and-snow::before { content: "\f164"; }
.qi-strong-convection::before { content: "\f165"; }
.qi-ozone::before { content: "\f166"; }
.qi-continuous-rain::before { content: "\f169"; }
.qi-waterlogging::before { content: "\f16a"; }
.qi-geological-hazard::before { content: "\f16b"; }
.qi-heavy-rainfall::before { content: "\f16c"; }
.qi-severely-falling-temperature::before { content: "\f16d"; }
.qi-snow-disaster::before { content: "\f16e"; }
.qi-wildfire-grassland::before { content: "\f16f"; }
.qi-medical-meteorology::before { content: "\f170"; }
.qi-thunderstorm::before { content: "\f171"; }
.qi-school-closure::before { content: "\f172"; }
.qi-factory-closure::before { content: "\f173"; }
.qi-maritime-risk::before { content: "\f174"; }
.qi-spring-dust::before { content: "\f175"; }
.qi-falling-temperature::before { content: "\f176"; }
.qi-typhoon-and-rainstorm::before { content: "\f177"; }
.qi-severe-cold::before { content: "\f178"; }
.qi-sand-dust::before { content: "\f179"; }
.qi-sea-thunderstorms::before { content: "\f17a"; }
.qi-sea-fog::before { content: "\f17b"; }
.qi-sea-thunder::before { content: "\f17c"; }
.qi-sea-typhoon::before { content: "\f17d"; }
.qi-low-temperature::before { content: "\f17e"; }
.qi-road-ice-and-snow::before { content: "\f17f"; }
.qi-thunderstorm-and-gale::before { content: "\f180"; }
.qi-continuous-low-temperature::before { content: "\f181"; }
.qi-strong-dust::before { content: "\f182"; }
.qi-short-lived-heavy-shower-rain::before { content: "\f183"; }
.qi-flood::before { content: "\f184"; }
.qi-mudflow::before { content: "\f185"; }
.qi-storm-surge::before { content: "\f186"; }
.qi-very-hot-weather::before { content: "\f187"; }
.qi-strong-monsoon-signal::before { content: "\f188"; }
.qi-landslip::before { content: "\f189"; }
.qi-tropical-cyclone::before { content: "\f18a"; }
.qi-fire-danger::before { content: "\f18b"; }
.qi-flooding-in-the-northern-new-territories::before { content: "\f18c"; }
.qi-cold-weather::before { content: "\f18d"; }
.qi-wind::before { content: "\f18e"; }
.qi-snow-ice::before { content: "\f18f"; }
.qi-fog::before { content: "\f190"; }
.qi-coastal-event::before { content: "\f191"; }
.qi-forest-fire::before { content: "\f192"; }
.qi-rain-flood::before { content: "\f194"; }
.qi-freezing-rain-icing::before { content: "\f195"; }
.qi-ground-frost::before { content: "\f196"; }
.qi-dust-raising-winds::before { content: "\f197"; }
.qi-strong-surface-winds::before { content: "\f198"; }
.qi-hot-day::before { content: "\f199"; }
.qi-warm-night::before { content: "\f19a"; }
.qi-cold-day::before { content: "\f19b"; }
.qi-thunderstorm-and-lightning::before { content: "\f19c"; }
.qi-hailstorm::before { content: "\f19d"; }
.qi-sea-area-warning::before { content: "\f19e"; }
.qi-fishermen-warning::before { content: "\f19f"; }
.qi-warning-default::before { content: "\f1a0"; }
.qi-sunny-fill::before { content: "\f1a1"; }
.qi-cloudy-fill::before { content: "\f1a2"; }
.qi-few-clouds-fill::before { content: "\f1a3"; }
.qi-partly-cloudy-fill::before { content: "\f1a4"; }
.qi-overcast-fill::before { content: "\f1a5"; }
.qi-clear-night-fill::before { content: "\f1a6"; }
.qi-cloudy-night-fill::before { content: "\f1a7"; }
.qi-few-clouds-night-fill::before { content: "\f1a8"; }
.qi-partly-cloudy-night-fill::before { content: "\f1a9"; }
.qi-shower-rain-fill::before { content: "\f1aa"; }
.qi-heavy-shower-rain-fill::before { content: "\f1ab"; }
.qi-thundershower-fill::before { content: "\f1ac"; }
.qi-heavy-thunderstorm-fill::before { content: "\f1ad"; }
.qi-thundershower-with-hail-fill::before { content: "\f1ae"; }
.qi-light-rain-fill::before { content: "\f1af"; }
.qi-moderate-rain-fill::before { content: "\f1b0"; }
.qi-heavy-rain-fill::before { content: "\f1b1"; }
.qi-extreme-rain-fill::before { content: "\f1b2"; }
.qi-drizzle-rain-fill::before { content: "\f1b3"; }
.qi-storm-fill::before { content: "\f1b4"; }
.qi-heavy-storm-fill::before { content: "\f1b5"; }
.qi-severe-storm-fill::before { content: "\f1b6"; }
.qi-freezing-rain-fill::before { content: "\f1b7"; }
.qi-light-to-moderate-rain-fill::before { content: "\f1b8"; }
.qi-moderate-to-heavy-rain-fill::before { content: "\f1b9"; }
.qi-heavy-rain-to-storm-fill::before { content: "\f1ba"; }
.qi-storm-to-heavy-storm-fill::before { content: "\f1bb"; }
.qi-heavy-to-severe-storm-fill::before { content: "\f1bc"; }
.qi-shower-rain-night-fill::before { content: "\f1bd"; }
.qi-heavy-shower-rain-night-fill::before { content: "\f1be"; }
.qi-rain-fill::before { content: "\f1bf"; }
.qi-light-snow-fill::before { content: "\f1c0"; }
.qi-moderate-snow-fill::before { content: "\f1c1"; }
.qi-heavy-snow-fill::before { content: "\f1c2"; }
.qi-snowstorm-fill::before { content: "\f1c3"; }
.qi-sleet-fill::before { content: "\f1c4"; }
.qi-rain-and-snow-fill::before { content: "\f1c5"; }
.qi-shower-snow-fill::before { content: "\f1c6"; }
.qi-snow-flurry-fill::before { content: "\f1c7"; }
.qi-light-to-moderate-snow-fill::before { content: "\f1c8"; }
.qi-moderate-to-heavy-snow-fill::before { content: "\f1c9"; }
.qi-heavy-snow-to-snowstorm-fill::before { content: "\f1ca"; }
.qi-shower-snow-night-fill::before { content: "\f1cb"; }
.qi-snow-flurry-night-fill::before { content: "\f1cc"; }
.qi-snow-fill::before { content: "\f1cd"; }
.qi-mist-fill::before { content: "\f1ce"; }
.qi-foggy-fill::before { content: "\f1cf"; }
.qi-haze-fill::before { content: "\f1d0"; }
.qi-sand-fill::before { content: "\f1d1"; }
.qi-dust-fill::before { content: "\f1d2"; }
.qi-duststorm-fill::before { content: "\f1d3"; }
.qi-sandstorm-fill::before { content: "\f1d4"; }
.qi-dense-fog-fill::before { content: "\f1d5"; }
.qi-strong-fog-fill::before { content: "\f1d6"; }
.qi-moderate-haze-fill::before { content: "\f1d7"; }
.qi-heavy-haze-fill::before { content: "\f1d8"; }
.qi-severe-haze-fill::before { content: "\f1d9"; }
.qi-heavy-fog-fill::before { content: "\f1da"; }
.qi-extra-heavy-fog-fill::before { content: "\f1db"; }
.qi-hot-fill::before { content: "\f1dc"; }
.qi-cold-fill::before { content: "\f1dd"; }
.qi-unknown-fill::before { content: "\f1de"; }
